const { Constants : ConstantsAccessor } = require( '@cox2m/pinpoint-accessor' );

module.exports = {
  UI_SET_KEY : 'ui.setKey',
  UI_MULTI_SET_KEY : 'ui.multiSetKey',

  LOGIN_PENDING : 'LOGIN_PENDING',
  VERIFY_PENDING : 'VERIFY_PENDING',
  LOGIN_FAILURE : 'LOGIN_FAILURE',
  LOGIN_SUCCESS : 'LOGIN_SUCCESS',
  LOGOUT : 'LOGOUT',

  ASSOC_PENDING : 'ASSOC_PENDING',
  ASSOC_SUCCESS : 'ASSOC_SUCCESS',
  ASSOC_FAILURE : 'ASSOC_FAILURE',

  RFA_PENDING : 'RFA_PENDING',
  RFA_SUCCESS_READY_FOR_USE : 'RFA_SUCCESS_READY_FOR_USE',
  RFA_SUCCESS_IN_RFA : 'RFA_SUCCESS_IN_RFA',
  RFA_FAILURE : 'RFA_FAILURE',
  DEFAULT_ERROR : 'There is an issue with this tracker that requires further analysis. Please use a different tracker.',

  // Copied from src/util/Constants.js in pinpoint-api-server
  // *NOTE*: Please update both places if necessary
  rfaStatuses : {
    readyForUse : { 
      status : 'readyForUse',
      copy : 'This tracker is in good condition and ready for use.'
    },
    notCommunicating : { 
      status : 'notCommunicating',
      copy : 'This tracker is not communicating. Please place it in the RFA bin. '
    },
    replaceBattery : { 
      status : 'replaceBattery',
      copy : 'This tracker has a low battery. Please place it in the RFA bin.'
    },
    defective : { 
      status : 'defective',
      copy : 'This tracker is defective. Please place it in the RFA bin.'
    },
    neverCommunicated : { 
      status : 'neverCommunicated',
      copy : 'This tracker has never communicated. Please place it in the RFA bin.'
    },
    hasOldFirmware : { 
      status : 'hasOldFirmware',
      copy : 'This tracker has old firmware. Please place it in the RFA bin.'
    },
    obdLowVoltage : { 
      status : 'obdLowVoltage',
      copy : 'This tracker has a low battery. Please place it in the RFA bin.'
    },
    obdBatteryDisconnected : { 
      status : 'obdBatteryDisconnected',
      copy : 'This tracker is defective. Please place it in the RFA bin.'
    },
    isBlackListed : { 
      status : 'isBlackListed',
      copy : 'This tracker is defective. Please place it in the RFA bin.'
    },
    obdAssocNotComm : { 
      status : 'obdAssocNotComm',
      copy : 'This tracker is defective. Please place it in the RFA bin.'
    },
  },

  uiLogosBucketUrl : 'https://storage.googleapis.com/cox2m-dealers-ui-logos/',

  MODE_PAIR : 'MODE_PAIR',
  MODE_UNPAIR : 'MODE_UNPAIR',
  TRACKER_HEALTHS : ConstantsAccessor.TRACKER_HEALTHS
}
