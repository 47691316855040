import { push } from 'connected-react-router'
import { Accessor } from '@cox2m/pinpoint-accessor'

import Constants from './Constants'
import Util from '../util'
import { gtmGetUsername, gtmEventPush, gtmEvents } from '../util/GoogleTagManager'

const { getSiteIdFromUrl } = Util.LocationUtil

/**
 * Associate/dissociate tracker 
 */
function associate() {
  return async ( dispatch, getState, { pinpointApi } ) => {
    dispatch( { type : Constants.ASSOC_PENDING } )
    const { ui } = getState()
    const { mode } = ui
    const trackerId = `${ui?.tracker?.value}` || undefined
    const assetId = ui.asset.value || undefined

    let result
    try {
      let assocUrl = 'v1/association';
      let postData = { assetId, trackerId, siteId : getSiteIdFromUrl() }

      if ( mode === Constants.MODE_PAIR ) {
        postData = { ...postData, ...{ vin : assetId } }
      } else if ( mode === Constants.MODE_UNPAIR ) {
        assocUrl = 'v1/disassociation'
      }

      const assocResult = await pinpointApi.child( assocUrl )
        .post( postData )

      result = { type : Constants.ASSOC_SUCCESS, assocResult }

      const { type } = assocResult

      const gtmData = {
        id : gtmGetUsername( getState() )
      }

      gtmEventPush( 
        type === 'disassociate' ? gtmEvents.disassociation : gtmEvents.association,
        gtmData )

      const assoc = 
        await pinpointApi.child( `v1/association?siteId=${getSiteIdFromUrl()}&trackerId=${trackerId}` ).get()

      if ( type === 'disassociate' ) {
        const trackerHealth = Accessor.calculateTrackerHealth( assoc )
        dispatch( { type : Constants.UI_SET_KEY, key : 'trackerHealth', value : trackerHealth } )
      }

    } catch ( error ) {
      result = { type : Constants.ASSOC_FAILURE, error }
    }
    dispatch( result )

    // If dissociating, check RFA status now
    if ( result.type === Constants.ASSOC_SUCCESS ) {
      if ( mode === Constants.MODE_UNPAIR ) {
        try {
          await dispatch( rfa() )
        } catch ( error ) {
          console.error( error )
        }
        dispatch( push( '/success' ) )
      } else {
        dispatch( push( '/success' ) )
      }
    } else {
      dispatch( push( '/failure' ) )
    }
    return result
  }
}

/**
 * Get RFA status of tracker (sets event too that why it is a post)
 */
function rfa() {
  return async ( dispatch, getState, { pinpointApi } ) => {
    
    dispatch( { type : Constants.RFA_PENDING } )
    // dispatch( { type : Constants.RFA_FAILURE, error : { status : 500, rfaStatus : 'readyforUse' } } )
    // throw 'new Error'
    const { ui } = getState()
    const trackerId = `${ui.tracker.value}` || null
    let result
    try {
      const rfaResult = await pinpointApi.child( `v1/rfa/site/${getSiteIdFromUrl()}/tracker/${trackerId}` ).post()
      const { rfaStatus } = rfaResult

      if ( rfaStatus === Constants.rfaStatuses.readyForUse.status ) {
        result = { type : Constants.RFA_SUCCESS_READY_FOR_USE, rfaResult }
      } else {
        result = { type : Constants.RFA_SUCCESS_IN_RFA, rfaResult }
      }

    } catch ( error ) {
      console.error( error )
      result = { type : Constants.RFA_FAILURE, error }
    }
    dispatch( result )
    return result
  }
}

function getVinInfo( vin ) {
  return async ( dispatch, getState, { pinpointApi } ) => {
    const res = await pinpointApi
      .child( 'v1/vin' )
      .get( {
        siteId : getSiteIdFromUrl(),
        vin
      } )

    return Object.values( res ).every( val => val === '' ) ? null : res
  }
}

/**
 * Check RFA status before association/pairing a tracker and assetId (vin)
 */
function checkRFABeforeAssociation() {
  return async ( dispatch, getState, { pinpointApi } ) => {
    dispatch( push( '/asset' ) )
  }
}

exports.associate = associate
exports.getVinInfo = getVinInfo
exports.checkRFABeforeAssociation = checkRFABeforeAssociation
