import React from 'react'
import Path from '@leverege/path'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Wave } from 'better-react-spinkit'
import { TextInput, Content, Flex, Label, Button, Text } from '@leverege/ui-elements'

import { Config } from '../../config'
import { Constants, LoginActions } from '../../actions'
import S from './LoginScreen.less'

class LoginScreen extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  };

  static propTypes = {
    auth : PropTypes.object.isRequired,
    login : PropTypes.func.isRequired
  };

  constructor( props ) {
    super( props )
    this.state = {
      username : '',
      password : '',
      showError : false,
      showPassInstructions : false
    }
  }

  onFieldChanged = ( field, value ) => this.setState( { [field] : value } )
  onUsername = value => this.onFieldChanged( 'username', value.value )
  onPassword = ( value ) => {
    const { username } = this.state
    const { login } = this.props
    if ( value.isReturn ) {
      this.setState( { showError : true } )
      login( username, value.value )
    }
    this.onFieldChanged( 'password', value.value )
  }

  login = ( evt ) => {
    const { username, password } = this.state
    const { login } = this.props
    this.setState( { showError : true } )
    login( username, password )
  }

  toggleShowPass = () => {
    const { showPassInstructions } = this.state
    this.setState( { showPassInstructions : !showPassInstructions } )
  }

  render() {
    const { username, password, showPassInstructions, showError } = this.state
    const { auth } = this.props
      
    let loginButton
    if ( LoginActions.isLoggingIn( auth ) ) {
      loginButton = <Wave color="#2C62FF" className={S.loginLoader} />
    } else {
      loginButton = (
        <Button className={S.loginButton} onClick={this.login} data-automation="submit-button">
          Login
        </Button>
      )
    }

    let formBoxContents
    if ( !showPassInstructions ) {
      formBoxContents = (
        <Flex className={S.formBox} direction="column">
          <Text data-automation="login-header-message" className={S.titleText}>
            Log in to Lot Vision
          </Text>
          <Flex direction="column" align="flex-start" className={S.loginPair}>
            <Label className={S.loginLabel}>
              Username
            </Label>
            <TextInput className={S.loginField} value={username} onChange={this.onUsername} data-automation="username-input"/>
          </Flex>
          <Flex direction="column" align="flex-start" className={S.loginPair}>
            <Label className={S.loginLabel}>
              Password
            </Label>
            <TextInput className={S.loginField} password value={password} onChange={this.onPassword} data-automation="password-input"/>
          </Flex>
          <Flex direction="column" className={S.thirdRow} justify="flex-start" align="flex-end">
            <Text data-automation="login-error-message" className={S.loginError}>{showError ? Path( 'error/message' ).get( auth, '' ) : null}</Text>
            <Text onClick={this.toggleShowPass} className={S.forgotPassword} data-automation="forgot-password-button">
              Forgot Password?
            </Text>
          </Flex>
          {loginButton}
        </Flex>
      )
    } else {
      formBoxContents = (
        <Flex className={S.formBox} direction="column">
          <Text data-automation="forgot-password-header" className={S.titleText}>
            Forgot Password
          </Text>
          <Flex direction="column" align="flex-start" className={S.loginPair}>
            <Label data-automation="receive-password-label" className={S.forgotPassHead}>
              Receive Password
            </Label>
            <Text data-automation="receive-password-message" className={S.forgotPassBody}>
              If you don’t know the account password, please consult with your manager.
            </Text>
          </Flex>
          <Flex direction="column" align="flex-start" className={S.loginPair}>
            <Label data-automation="reset-password-label" className={S.forgotPassHead}>
              Reset Password
            </Label>
            <Text data-automation="reset-password-message" className={S.forgotPassBody}>
              If you are an admin and need to reset the password, please email Cox2M.
            </Text>
          </Flex>
          <Button onClick={this.toggleShowPass} className={S.loginButton} data-automation="back-to-login-button">
            Back to Log in
          </Button>
        </Flex>
      )
    }

    return (
      <Content className={S.container}>
        <Content.Area>
          <Flex className={S.noPadding} align="center" direction="column">
            <Flex className={S.header} align="center" justify="flex-start">
              <img alt="Cox2M Logo" className={S.logo} src={`${Constants.uiLogosBucketUrl}${Config.PROJECT_NAME}-logo.png`} data-automation="cox2m-logo"/>
            </Flex>
            <Flex className={S.topBackground} />
            <Flex className={S.bottomBackground} />
            {formBoxContents}
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    auth : state.auth
  } ),
  dispatch => ( {
    login : ( username, password ) => {
      dispatch( LoginActions.login( username, password ) )
    }
  } )
)( LoginScreen )
