import React from 'react'
import { connect } from 'react-redux'
import { Content } from '@leverege/ui-elements'
import { Route, Switch } from 'react-router-dom'

import Header from './components/header/Header'
import ScanHeader from './components/scanHeader/ScanHeader'
import Home from './components/home/Home'
import ScanTracker from './components/scanTracker/ScanTracker'
import ScanAsset from './components/scanAsset/ScanAsset'
import LoginScreen from './components/login/LoginScreen'
import AssocSuccess from './components/assocSuccess/AssocSuccess'
import AssocFailure from './components/assocFailure/AssocFailure'
import RFA from './components/rfa/RFA'
import RFAFailure from './components/rfaFailure/RFAFailure'
import LoadingScreen from './components/loadingScreen/LoadingScreen'
import { LoginActions } from './actions'
import Util from './util'

class App extends React.Component {
  
  render() {
    const { auth, router } = this.props
    const siteId = Util.LocationUtil.getSiteIdFromUrl()

    if ( LoginActions.isVerifying( auth ) ) {
      return <LoadingScreen />
    } if ( LoginActions.isLoggingIn( auth ) || !LoginActions.isLoggedIn( auth ) ) {
      return <LoginScreen />
    }

    return (
      <Content>
        <Content.Header>
          <Switch location={router.location}>
            <Route
              component={ScanHeader}
              path={[ '/asset', '/tracker', '/success', '/failure', '/rfa', '/rfaFailure' ]} />
            <Route component={Header} />
          </Switch>
        </Content.Header>
        <Content.Area>
          <Switch location={router.location}>
            <Route render={props => <ScanAsset allowInvalidVin={siteId === 'lab'} />} path="/asset" />
            <Route component={ScanTracker} path="/tracker" />
            <Route component={AssocSuccess} path="/success" />
            <Route component={AssocFailure} path="/failure" />
            <Route component={RFA} path="/rfa" />
            <Route component={RFAFailure} path="/rfaFailure" />
            <Route component={Home} />
          </Switch>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    auth : state.auth,
    router : state.router
  } )
)( App )
